<template>
  <v-card>
    <v-card-title>サジェスチョン</v-card-title>
    <v-card-text>
      <v-tabs v-model="tabs" fixed-tabs>
        <v-tab>全て</v-tab>
        <v-tab>未読</v-tab>
        <v-tab>既読</v-tab>
      </v-tabs>
      <v-row justify="center">
        <v-col cols="11">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="検索"></v-text-field>
        </v-col>
      </v-row>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-data-table :headers="headers" :items="all" :search="search" @click:row="details">
            <template v-slot:item.action="{ item }">
              <v-icon small @click.stop="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table :headers="headers" :items="unreads" :search="search" @click:row="details">
            <template v-slot:item.action="{ item }">
              <v-icon small @click.stop="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table :headers="headers" :items="reads" :search="search" @click:row="details">
            <template v-slot:item.action="{ item }">
              <v-icon small @click.stop="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>{{selected.created}}</v-card-title>
        <v-card-text>{{selected.message}}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="primary" @click="dialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import format from 'date-fns/format'

export default {
  name: 'suggestions',
  props: {
    suggestions: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    tabs: 1,
    search: '',
    headers: [
      {
        text: '受信日時',
        align: 'center',
        sortable: true,
        value: 'created'
      },
      {
        text: 'サジェスチョン',
        align: 'center',
        sortable: true,
        value: 'message'
      },
      {
        text: '操作',
        align: 'center',
        sortable: false,
        value: 'action'
      }
    ],
    dialog: false,
    selected: {
      created: '', message: ''
    }
  }),
  computed: {
    all () {
      return this.suggestions.map(s => this.formatDateTime(s))
    },
    unreads () {
      return this.suggestions.filter(s => !s.read).map(s => this.formatDateTime(s))
    },
    reads () {
      return this.suggestions.filter(s => s.read).map(s => this.formatDateTime(s))
    }
  },
  methods: {
    formatDateTime (suggestion) {
      return {
        ...suggestion,
        created: format(suggestion.created, 'yyyy/MM/dd HH:mm')
      }
    },
    details (selected) {
      this.selected = selected
      this.dialog = true
      if (!selected.read) {
        this.$emit('read', selected)
      }
    },
    deleteItem (item) {
      this.$emit('delete', item)
    }
  }
}
</script>
