<template>
  <suggestions
    :suggestions="suggestions"
    @read="onRead"
    @delete="onDelete"
  ></suggestions>
</template>

<script>
import { mapState } from 'vuex'
import { getSuggestions, readSuggestion, deleteSuggestion } from '@/helpers/firebase-functions'
import suggestions from '@/components/suggestions/Suggestions'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    suggestions
  },
  data: () => ({
    suggestions: []
  }),
  computed: {
    ...mapState('auth', ['uid'])
  },
  methods: {
    async getSuggestions () {
      const { data: suggestions } = await getSuggestions({ ownerId: this.uid, ownerScenarioId: this.ownerScenarioId })
      this.suggestions = suggestions.map(s => {
        return {
          ...s,
          created: new Date(s.created),
          deleted: s.deleted ? s.deleted : false
        }
      })
    },
    async onRead (suggestion) {
      await readSuggestion({ ownerId: this.ownerId, ownerScenarioId: this.ownerScenarioId, suggestionId: suggestion.id })
      this.getSuggestions()
    },
    async onDelete (suggestion) {
      await deleteSuggestion({ ownerId: this.ownerId, ownerScenarioId: this.ownerScenarioId, suggestionId: suggestion.id })
      this.getSuggestions()
    }
  },
  async mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_SUGGESTION)
    this.ownerId = this.uid
    this.ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    this.getSuggestions()
  }
}
</script>
